/* eslint-disable */
import { mapGetters } from 'vuex'

export const staffUserMixin = {
  computed: {
    ...mapGetters({
      userLogin: 'user/userLogin',
      userStaffLogin: 'user/userStaffLogin'
    }),
    isStaffMember() {
      return (
        (!!this.userStaffLogin.campnumber && this.userStaffLogin.campnumber === 'U50000') ||
        this.userLogin.campnumber === 'U50000'
      )
    }
  }
}
